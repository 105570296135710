/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
const awsmobile = {
  "aws_project_region": "eu-central-1",
  "aws_appsync_graphqlEndpoint": "https://pbaykvskjvcivhnqvrhjnno7x4.appsync-api.eu-central-1.amazonaws.com/graphql",
  "aws_appsync_region": "eu-central-1",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
  "aws_appsync_apiKey": "null",
  "aws_cognito_identity_pool_id":  "eu-central-1:92eac1f7-eaf8-4b3a-9d80-99b3b9170941",
  "aws_cognito_region": "eu-central-1",
  "aws_user_pools_id": "eu-central-1_NbYGtV91M",
  "aws_user_pools_web_client_id": "7t3v29e8v888472b6op748mqvc",
  "oauth": {},
  "region": "eu-central-1",
  "identityPoolRegion": "eu-central-1",
  "identityPoolId": "eu-central-1:92eac1f7-eaf8-4b3a-9d80-99b3b9170941",  
  "userPoolId": "eu-central-1_NbYGtV91M",
  "userPoolWebClientId": "7t3v29e8v888472b6op748mqvc",
  "aws_user_files_s3_bucket": "esend-application-content-prod",
  "aws_user_files_s3_bucket_region": "eu-central-1",
  "esend_driver_pictures": "esend-prod-driver-pictures",
  "esend_invoicing": "esend-invoicing-prod",
  "esend_environment": "production",
  "build_env": "production"
};


export default awsmobile;
